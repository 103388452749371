const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/6.png",
    text: "6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/7.png",
    text: "7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/8.png",
    text: "8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloornorthey/9.png",
    text: "9",
  },
];

export default data;
